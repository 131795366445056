import React from 'react';
import {
  Box,
  Heading,
  Text,
  Flex,
  IconButton,
  VStack,
  HStack,
  Button,
} from '@chakra-ui/react';
import { FaWhatsapp } from 'react-icons/fa';

const ContactCard = () => {
  const handleWhatsAppClick = () => {
    const whatsappUrl ='https://wa.me/447537176105';
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Flex
      w="100%"
      minH="50vh"
      alignItems="center"
      justifyContent="center"
      bgGradient="linear(to-t, red.400, red.400)"
      boxShadow="xl"
      position="relative"
      overflow="hidden"
    >
      <Box
        w="90%"
        maxW="5xl"
        p={6}
        bg="white"
        borderRadius="xl"
        boxShadow="lg"
        color="gray.800"
        textAlign="center"
        position="relative"
        zIndex={1}
      >
        <Heading as="h2" size="xl" color="red.600" mb={2}>
          Contact Us
        </Heading>
        <Text
          fontSize={{ base: 'md', md: 'lg', lg: 'xl' }}
          mt={{ base: 2, md: 4 }}
          color="gray.600"
        >
          If you run a Satta market or want to share game results, feature them on our platform. Contact us on WhatsApp for details!
        </Text>
        <Button
          mt={6}
          colorScheme="green"
          variant="outline"
          _hover={{ bg: 'green.400', color: 'white' }}
          onClick={handleWhatsAppClick}
        >
          Contact Us on WhatsApp
        </Button>
      </Box>
      <Box
        position="absolute"
        top="-10px"
        left="-10px"
        right="-10px"
        bottom="-10px"
        bg="red.600"
        zIndex={0}
        transform="rotate(-4deg)"
      />
    </Flex>
  );
};

export default ContactCard;
