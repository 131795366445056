import React, { useState, useEffect } from 'react';
import moment from 'moment';
import '../Style/SingleDigitBulk.css'; // Importing CSS for styling
import { useHeading } from '../ContextProvider/HeadingContext';
import gameTimingData from '../gameTimingData.json'; // Import your JSON data
import { useToast, Switch, FormLabel, Text, FormControl, IconButton, Input, Box, Heading, Button, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton, } from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import {jwtDecode} from 'jwt-decode'; // Fixing incorrect import assumption

const SingleDigitBulk = () => {
  const [points, setPoints] = useState('');
  const [totalPoints, setTotalPoints] = useState(0);
  const [bids, setBids] = useState([]);
  const [toggleBidType, setToggleBidType] = useState(false);
  const [isToggleDisabled, setIsToggleDisabled] = useState(false);
  const { heading } = useHeading(); // Get the heading from context
  const [username, setUsername] = useState('');
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUsername(decodedToken.username);
    }
  }, []);

  useEffect(() => {
    const gameData = gameTimingData.find((game) => game.title === heading);
    if (gameData) {
      const checkBidStatus = () => {
        const now = moment();
        const openBidTime = moment(gameData.openBidLastTime, 'h:mm A', 'Asia/Kolkata');
        const closeBidTime = moment(gameData.closeBidLastTime, 'h:mm A', 'Asia/Kolkata');
        const resetTime = moment('1:00 AM', 'h:mm A', 'Asia/Kolkata');

        if (now.isBefore(openBidTime)) {
          setToggleBidType(false);
          setIsToggleDisabled(false);
        } else if (now.isAfter(openBidTime) && now.isBefore(closeBidTime)) {
          setToggleBidType(true);
          setIsToggleDisabled(false);
        } else if (now.isAfter(closeBidTime)) {
          setToggleBidType(true);
          setIsToggleDisabled(true);
        } else if (now.isAfter(resetTime)) {
          setToggleBidType(false);
          setIsToggleDisabled(false);
        }
      };
      checkBidStatus();
      const interval = setInterval(checkBidStatus, 5000);
      return () => clearInterval(interval);
    }
  }, [heading]);
  const confirmSubmit = async () => {
    setIsModalOpen(true); // Close modal
    // Your existing form submission logic here...
  };
  const handleSubmit = async () => {
    setIsModalOpen(false); // Close modal
    const token = localStorage.getItem('token');
    if (!token) {
      toast({
        title: 'Authorization Error',
        description: 'Please log in to place a bet.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    try {
      setLoading(true); // Start loading

      const selectedNumbersArray = bids.map((bid) => ({
        number: bid.number,
        amount: bid.points,
      }));
      const totalBetAmount = selectedNumbersArray.reduce((acc, curr) => acc + curr.amount, 0);
      const betType = toggleBidType ? 'Close' : 'Open';

      const response = await fetch('https://mainproject-ul4a.onrender.com/account/bet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          username,
          betAmount: totalBetAmount,
          gameName: heading,
          category: 'Single Digit',
          selectedNumbers: selectedNumbersArray,
          betType: betType,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        const errorTitle = data.message.includes('Insufficient funds') ? 'Insufficient Balance' : 'Invalid Bet';
        toast({
          title: errorTitle,
          description: data.message || 'There was an error placing your bet.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
        return;
      }

      // Handle win/loss response
      if (data.winStatus === 'Pending result') {
        toast({
          title: 'Bet Submitted',
          description: 'Bet placed successfully. Result is pending.',
          status: 'info',
          duration: 4000,
          isClosable: true,
        });
      } else if (data.winStatus === 'You won!') {
        toast({
          title: 'Congratulations! You Won!',
          description: `Your Winning Amount: ${data.winningAmount}`,
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Better luck next time!',
          description: 'You lost the bet.',
          status: 'warning',
          duration: 4000,
          isClosable: true,
        });
      }

      // Reset state
      setBids([]);
      setTotalPoints(0);
    } catch (error) {
      console.error('Error submitting bet:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while submitting your bet.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleAddBid = (number) => {
    const pointsNumber = parseInt(points);

    if (isNaN(number) || number < 0 || number > 9) {
      toast({
        title: 'Invalid Bid Number',
        description: 'Please enter a valid bid number between 0 and 9.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (points === '' || isNaN(pointsNumber) || pointsNumber <= 0) {
      toast({
        title: 'Invalid Points',
        description: 'Please enter valid points greater than 0.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (pointsNumber > 1000) {
      toast({
        title: 'Maximum Points Exceeded',
        description: 'Maximum bid points allowed is 1000.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (totalPoints + pointsNumber > 5000) {
      toast({
        title: 'Points Limit Warning',
        description: 'You cannot add more than 5000 points in total.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }


    const bidType = toggleBidType ? 'Close' : 'Open';

 
 // Check if the bid time is expired and the toggle is disabled
 if (isToggleDisabled && !toggleBidType) {
  toast({
    title: 'Time Expired',
    description: 'You cannot place an Open bid after the allowed time.',
    status: 'warning',
    duration: 4000,
    isClosable: true,
  });
  return;
}

// If toggle is disabled and it's a Close bid, prevent adding the bid
if (isToggleDisabled && toggleBidType) {
  toast({
    title: 'Time Expired',
    description: 'You cannot place a bet as the bidding time has expired.',
    status: 'warning',
    duration: 4000,
    isClosable: true,
  });
  return;
}

    setBids((prevBids) => {
      const existingBidIndex = prevBids.findIndex(
        (item) => item.number === number && item.type === bidType
      );
      if (existingBidIndex !== -1) {
        const updatedBids = [...prevBids];
        updatedBids[existingBidIndex].points += pointsNumber;
        return updatedBids;
      }
      return [
        ...prevBids,
        { id: Date.now(), number, points: pointsNumber, type: bidType },
      ];
    });

    setTotalPoints((prevTotal) => prevTotal + pointsNumber);
    setPoints('');
  };

  const handleDeleteBid = (id) => {
    const deletedBid = bids.find((item) => item.id === id);
    setTotalPoints(totalPoints - deletedBid.points);
    setBids(bids.filter((item) => item.id !== id));
  };

  const renderItem = (item) => (
    <div className="bidItem" key={item.id}>
      <span className="bidText">{item.number}</span>
      <span className="bidText">{item.points}</span>
      <span className="bidText">{item.type}</span>
      <IconButton
        icon={<DeleteIcon />}
        onClick={() => handleDeleteBid(item.id)}
        aria-label="Delete Bid"
        variant="outline"
        colorScheme="red"
      />
    </div>
  );
  return (
    <div className="container">
       <Box
        bg="linear-gradient(to right, red.600, red.500, black)"
        p={{ base: 3, sm: 3, md: 5 }} // Adjust padding based on screen size
        textAlign="center"
        borderRadius="lg"
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="150px"
        width="100%" // Ensures full width
      >
        <Heading
          as="h1"
          size={{ base: 'sm', sm: 'xl', md: 'lg' }} // Adjust font size for different screen sizes
          color="black"
          fontWeight="bold"
          letterSpacing="wider"
          textTransform="uppercase"
          fontFamily="'Orbitron', sans-serif"
          position="relative"
          lineHeight="1.2"
          p={{ base: 2, sm: 2, md: 5 }} // Adjust padding based on screen size
          borderBottom={'3px solid #d2122e'}
        >
          {heading} | Single Digit Bulk
        </Heading>
      </Box>
      <div className="header">
        <Box display="flex" alignItems="center">
          <span className="calendar-icon">&#x1F4C5;</span>
          <span>{moment().format('MMMM DD, YYYY')}</span>
        </Box>
        <div className="toggleContainer">
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="bid-type-toggle" mb="0" mr="3">
              {toggleBidType ? 'Close' : 'Open'}
            </FormLabel>
            <Switch
              id="bid-type-toggle"
              isChecked={toggleBidType}
              onChange={() => setToggleBidType(!toggleBidType)}
              isDisabled={isToggleDisabled}
              colorScheme="red" // Change the toggle button color to red
            />
          </FormControl>
        </div>
      </div>
      <div className="inputContainer">
        <label className="label">Points:</label>
        <input
          className="input"
          value={points}
          onChange={(e) => setPoints(e.target.value)}
          type="number"
          placeholder="Enter points"
        />
      </div>
      <div className="numberContainer">
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
          <button
            key={number}
            className="numberButton"
            onClick={() => handleAddBid(number)}
          >
            <span className="numberText">{number}</span>
            {bids
              .filter((bid) => bid.number === number)
              .map((bid) => (
                <span key={bid.id} className="pointsText">{bid.points} </span>
              ))}
          </button>
        ))}
      </div>
      <div className="tableContainer">
        <div className="tableHeader">
          <span className="headerText">Digit</span>
          <span className="headerText">Points</span>
          <span className="headerText">Type</span>
          <span className="headerText">Delete</span>
        </div>
        <div>
          {bids.map(renderItem)}
        </div>
      </div>
      <div className="footer" mt={4}>
        {bids.length > 0 && (
          <>
            <Text fontSize={{ base: 'sm', sm: 'md' }} mb={2}>Total Bids: {bids.length}</Text>
            <Text fontSize={{ base: 'sm', sm: 'md' }} mb={4}>Total Points: {totalPoints}</Text>
            <button
              className="submitButton"
              onClick={confirmSubmit}
              disabled={loading}
              style={{
                backgroundColor: '#d2122e',
                color: 'white',
                padding: '10px 20px',
                fontSize: '16px',
                borderRadius: '8px',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              {loading ? 'Loading...' : 'Submit'}
            </button>
          </>
        )}
      </div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} isCentered>
        <ModalOverlay />
        <ModalContent
          bg="white" // White background for clean design
          color="gray.800" // Dark text for contrast
          borderRadius="md"
          boxShadow="xl"
          mx="4"
          maxW={{ base: "90%", md: "500px" }}
          p={4}
        >
          <ModalHeader fontSize="lg" fontWeight="bold" textAlign="center" color="#d2122e">
            Confirm Bet Submission
          </ModalHeader>
          <ModalCloseButton color="gray.700" />
          <ModalBody>
            <Text fontWeight="medium" mb={3} borderBottom="1px solid" borderColor="#d2122e" pb={2}>
              Bet Details:
            </Text>
            <Box
              bg="gray.100"
              p={3}
              borderRadius="md"
              boxShadow="sm"
              maxH="300px"
              overflowY="auto"
            >
              {bids.map((bid, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  p={2}
                  bg="gray.50"
                  mb={2}
                  borderRadius="md"
                  _hover={{ bg: "gray.200", transform: "scale(1.01)" }}
                  transition="all 0.2s"
                >
                  <Text fontSize="sm">Digit: <strong>{bid.number}</strong></Text>
                  <Text fontSize="sm">Points: <strong>{bid.points}</strong></Text>
                  <Text fontSize="sm">Type: <strong>{bid.type}</strong></Text>
                </Box>
              ))}
            </Box>
            <Text mt={4} fontWeight="bold" fontSize="md" color="gray.600">
              Total Points: <span style={{ color: "#2D3748" }}>{totalPoints}</span>
            </Text>
            <Text mt={2} fontSize="xs" color="gray.500">
              Please review all details before confirming your bet.
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button
              bg="#d2122e" // Use your custom red color for the Confirm button
              color="white"
              mr={3}
              onClick={handleSubmit}
              size="sm"
              _hover={{ bg: "#a20e24", boxShadow: "0 0 8px rgba(210, 18, 46, 0.5)" }}
              transition="all 0.2s"
            >
              Confirm
            </Button>
            <Button
              variant="outline"
              onClick={() => setIsModalOpen(false)}
              size="sm"
              borderColor="gray.400"
              _hover={{ bg: "gray.200", color: "gray.800" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SingleDigitBulk;
