import React from "react";
import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
} from "@chakra-ui/react";
import { FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

// SocialIcon Component
function SocialIcon({ icon, href }) {
  return (
    <Link
      to={href}
      style={{
        display: "flex",
        alignItems: "center",
        color: "grey",
        padding: "8px",
        fontSize: "20px",
      }}
    >
      {icon}
    </Link>
  );
}

// Footer Component
function Footer() {
  return (
    <Box
      bg="black"
      py={5}
      color="#d2122e"
      width="100%"
    >
      <Container maxW="6xl">
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          spacing={{ base: 4, md: 6 }}
        >
          {/* Logo Section */}
          <Box>
            <Link to="/" fontWeight="bold">
              <Image
                src="/images/logo-no-background.png"
                alt="Logo"
                width={{ base: "120px", md: "180px" }}
                height="auto"
              />
            </Link>
          </Box>

          {/* Links Section */}
          <Stack direction={{ base: "column", md: "row" }} spacing={8}>
            {/* Quick Links */}
            <Stack spacing={2}>
              <Text fontWeight="bold" fontSize={{ base: "sm", md: "md" }}>
                Quick Links
              </Text>
              <Stack spacing={1}>
                <Link to="/gamerules">Satta Matka Results</Link>
                <Link to="/gamerules">Expert Matka Tips</Link>
                <Link to="/">Live Matka Updates</Link>
                <Link to="/terms">Contact Us</Link>
              </Stack>
            </Stack>

            {/* Popular Charts */}
            <Stack spacing={2}>
              <Text fontWeight="bold" fontSize={{ base: "sm", md: "md" }}>
                Popular Charts
              </Text>
              <Stack spacing={1}>
                {[
                  'SRIDEVI',
                  'TIME BAZAR',          
                  'MILAN DAY',
                  'RAJDHANI DAY',
                  'KALYAN',
                  'SRIDEVI NIGHT',      
                  'MILAN NIGHT',
                  'KALYAN NIGHT',
                  'RAJDHANI NIGHT',
                ].map((chart) => (
                  <Link
                    key={chart}
                    to={`/pannel/table/${chart}`}
                  >
                    {chart} Chart
                  </Link>
                ))}
              </Stack>
            </Stack>

            {/* Company Links */}
            <Stack spacing={2}>
              <Text fontWeight="bold" fontSize={{ base: "sm", md: "md" }}>
                Company
              </Text>
              <Stack spacing={1}>
                <Link to="/charts/page">Charts</Link>
                <Link to="/gamerules">Game Rules</Link>
                <Link to="/aboutus">About Us</Link>
                <Link to="/terms">Terms & Conditions</Link>
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Text fontWeight="bold" fontSize={{ base: "sm", md: "md" }}>
              Similar Websites on internet
            </Text>
            <Stack spacing={1}>
              <Link
                to="https://dpbossss.services/"
                target="_blank"
                rel="noopener noreferrer"
              >
                DP Boss Services
              </Link>
              <Link
                to="https://spboss.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                SP Boss
              </Link>
              <Link
                to="https://gama567.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Gama567
              </Link>
              <Link
                to="https://matkafun.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                MatkaFun
              </Link>
              <Link
                to="https://tara567.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tara567
              </Link>
            </Stack>
          </Stack>
          {/* Stay Connected Section */}
          <Stack spacing={2}>
            <Text fontWeight="bold" fontSize={{ base: "sm", md: "md" }}>
              Stay Connected
            </Text>
            <Stack direction="row" spacing={3}>
              <SocialIcon icon={<FaTwitter />} href="/" />
              <SocialIcon icon={<FaYoutube />} href="/" />
              <SocialIcon icon={<FaInstagram />} href="/" />
            </Stack>
          </Stack>
        </Stack>

        <Box mt={4}>
          <Text fontSize={{ base: "xs", md: "sm" }} color="gray.500">
            Satta Matka, Matka game, Satta, Matka, Kalyan Matka, Milan Day, Milan Night, Jodi, Pana, Open Close, Single Digit, Double Digit, Triple Digit, Chart, Results, Winning Numbers, Kalyan Result, Kalyan Open, Kalyan Close, Jodi Digit, Matka Tips, Matka Tricks, Kalyan Matka Result, Mumbai Matka, Record Matka, Tips and Tricks, Fixed Matka, Satta Matka Game, Satta Tips, Matka Formula, Game Result, Best Matka, Satta Market, Kalyan Market, Matka Bazar, Play Satta, Matka Fixing, Fast Matka, Matka Raj, Kalyan Raj, Satta Raja, Matka Book, Matka Boss, Matka Panel, Satta Guru, Kalyan Guru, Matka Babu, Matka Panel Chart, Satta Chart, Jodi Chart, Matka Market, Matka Boss Result, Satta Boss, Matka Bazar Result, Gali Result, Matka Prediction, Satta Prediction, Matka Guessing, Matka Predictions, Tips for Satta, Satta Game Tips, Satta Winning Numbers, Gali Satta, Bazar Satta, Satta King, Satta Master, Satta King Result, Matka Result, Matka Time, Matka Result Today, Kalyan Time, Jodi Open, Jodi Close, Matka Closed, Matka Open, Satta Open, Satta Close, Kalyan Tips, Kalyan Trick, Mumbai Tips, Matka Mumbai, Satta Mumbai, Satta Dealer, Satta Number, Satta Game Tips, Matka Winner, Satta Matka Winners, Winning Jodi, Matka Guess, Matka Fix, Best Matka Tips, Matka Numerology, Satta Calculation, Satta Formula, Gali Open, Gali Close, Matka Charts, Number Matka, Matka Fixing System, Matka Bazar Chart, Mumbai Matka Tips, Kalyan Matka Open, Satta Close Game, Kalyan Open Result, Satta Prediction Numbers.
          </Text>
        </Box>

        {/* Disclaimer Section */}
        <Box mt={4}>
          <Text fontSize={{ base: "xs", md: "sm" }} color="gray.500">
            <Text as="strong" color="red.500">Disclaimer:</Text>
            Visiting this site and browsing it is strictly recommended at your own risk. Every information available here is only for informational purposes and is based on astrology and number calculations. We are not associated or affiliated with any illegal Matka business. We ensure compliance with the rules and regulations of the regions where this website is accessed. There may be instances where this website is banned in certain areas. If you continue to use it in those areas, you do so at your own risk. Copying any information or content posted on the website is strictly prohibited and may result in legal action.
          </Text>
        </Box>

        {/* Footer Bottom */}
        <Text
          textAlign="center"
          color="grey"
          fontSize={{ base: "xs", md: "sm" }}
          mt={4}
        >
          Copyright © 2025 Maharaj 567. All rights reserved.
        </Text>
      </Container>
    </Box>
  );
}

// Main Layout Component
function Layout({ children }) {
  return (
    <Flex direction="column">
      <Box flex="1">{children}</Box>
      <Footer />
    </Flex>
  );
}

export default Layout;
