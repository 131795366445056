// src/App.js
import React, { useState, useEffect } from 'react';
import "./global.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './Components/HomePage';
import AboutUs from './Components/AboutUs';
import Howtoplay from './Components/HowtoPlay';
import TermAndConditions from './Components/TermAndConditions';
import ChartPage from './Components/Chart/ChartPage';
import Navbar from './Components/NavbarPage';
import Footer from './Components/FooterPage';
import Login from './Components/Auth/Login';
// import Withdraw from './Components/Withdraw';
// import Deposit from './Components/Deposit';
import BetHistory from './Components/BetHistory';
import WalletTransactions from './Components/Statement';
import CreditTransaction from './Components/Credit';
import DebitTransaction from './Components/Debit';
import RecordsTable from './Components/Chart/RecordsTable';
import PanelChart from './Components/Chart/PanelChartTable';
import ComingSoon from './Components/downloadpage';
import NotificationDetails from './Components/Notification';
import SingleDigit from './Components/games/SingleDigit';
import SingleDigitBulk from './Components/games/SingleDigitBulk';
import JodiDigit from './Components/games/JodiDigit';
import JodiDigitBulk from './Components/games/JodiDigitBulk';
import GameScreen from './Components/gamescreen';
import OddEven from './Components/games/OddEven';
import SinglePana from './Components/games/SinglePana';
import DoublePana from './Components/games/DoublePana';
import TriplePana from './Components/games/TriplePana';
import DefaultScreen from './Components/DefaultScreen';
import { useAuth } from './Components/AuthContext';
import BankDetails from './Components/BankDetails';
import SInglePanaBulk from './Components/games/SInglePanaBulk';
import DoublePanaBulk from './Components/games/DoublePanaBulk';
import ScrollAuto from './Components/scrollato';
const App = () => {
  const { user } = useAuth(); // Assume this hook provides user authentication context

  // State to manage access control for games
  const [accessGranted, setAccessGranted] = useState(() => {
    // Initialize from localStorage if available
    return localStorage.getItem('accessGranted') === 'true';
  });

  useEffect(() => {
    // Persist access state to localStorage
    localStorage.setItem('accessGranted', accessGranted);
  }, [accessGranted]);

  // Protected route for general authentication
  const ProtectedRoute = ({ children }) => {
    if (!user) {
      // Redirect unauthenticated users to the login page
      return <Navigate to="/login" />;
    }
    return children;
  };

  // Protected route for game access
  const ProtectedGameRoute = ({ children }) => {
    if (!accessGranted) {
      // Redirect to DefaultScreen if access is not granted
      return <Navigate to="/default" />;
    }
    return children;
  };

  // Wrapper for GameScreen to grant access
  const GameScreenWrapper = () => {
    // Grant access upon visiting GameScreen
    setAccessGranted(true);
    return <GameScreen />;
  };

  return (
    <Router>
      <ScrollAuto />
         <Navbar />
        <Routes>
        <Route path="/" element={<HomePage />} />  
        <Route path="/login" element={<Login />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/gamerules" element={<Howtoplay />} />
        <Route path="/terms" element={<TermAndConditions />} />
        <Route path="/charts/page" element={<ChartPage />} />
        <Route path="/records/table/:id" element={<RecordsTable />} />
        <Route path="/pannel/table/:id" element={<PanelChart />} />  
        <Route path="/notification" element={<NotificationDetails />} />         
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <>
                <Routes>
                        
                  <Route path="/download-app" element={<ComingSoon />} />         
                  <Route path="/bethistory" element={<BetHistory />} />
                  <Route path="/statement" element={<WalletTransactions />} />
                  <Route path="/credit" element={<CreditTransaction />} />
                  <Route path="/debit" element={<DebitTransaction />} />
                  <Route path="/addbankdeatils" element={<BankDetails />} />
                  <Route path="/default" element={<DefaultScreen />} />

                  {/* Protected game routes */}
                  <Route path="/gamescreen/:gameName/singledigit" element={<ProtectedGameRoute><SingleDigit /></ProtectedGameRoute>} />
                  <Route path="/gamescreen/:gameName/singledigitbulk" element={<ProtectedGameRoute><SingleDigitBulk /></ProtectedGameRoute>} />
                  <Route path="/gamescreen/:gameName/jodidigit" element={<ProtectedGameRoute><JodiDigit /></ProtectedGameRoute>} />
                  <Route path="/gamescreen/:gameName/jodidigitbulk" element={<ProtectedGameRoute><JodiDigitBulk /></ProtectedGameRoute>} />
                  <Route path="/gamescreen/:gameName/oddeven" element={<ProtectedGameRoute><OddEven /></ProtectedGameRoute>} />
                  <Route path="/gamescreen/:gameName/singlepana" element={<ProtectedGameRoute><SinglePana /></ProtectedGameRoute>} />
                  <Route path="/gamescreen/:gameName/singlepanabulk" element={<ProtectedGameRoute><SInglePanaBulk /></ProtectedGameRoute>} />
                  <Route path="/gamescreen/:gameName/doublepana" element={<ProtectedGameRoute><DoublePana /></ProtectedGameRoute>} />
                  <Route path="/gamescreen/:gameName/doublepanabulk" element={<ProtectedGameRoute><DoublePanaBulk /></ProtectedGameRoute>} />
                  <Route path="/gamescreen/:gameName/triplepana" element={<ProtectedGameRoute><TriplePana /></ProtectedGameRoute>} />
                  <Route path="/gamescreen/:gameName" element={<GameScreenWrapper />} />
                </Routes>
               
              </>
            </ProtectedRoute>
          }
        />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
