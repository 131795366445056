import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  VStack,
  Heading,
  HStack,
  Button,
} from '@chakra-ui/react';
import { FaSyncAlt } from 'react-icons/fa';

const LuckyNumber = () => {
  const [numbers, setNumbers] = useState([null, null, null, null]);

  const fetchUpdatedData = async () => {
    try {
      const response = await fetch('https://mainproject-ul4a.onrender.com/result/getdata');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const firstItem = data[0];
      setNumbers([firstItem.number1, firstItem.number2, firstItem.number3, firstItem.number4]);
    } catch (error) {
      console.error('Error fetching updated data:', error);
    }
  };

  const handleRefresh = () => {
    window.location.reload(); // Reloads the current page
  };

  useEffect(() => {
    fetchUpdatedData();

    const intervalId = setInterval(() => {
      fetchUpdatedData();
    }, 60000); // Fetch data every 2 minutes

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const timerId = setInterval(() => {
      // setCurrentTime(new Date()); // If you plan to use this, uncomment it
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  return (
    <Box
      textAlign="center"
      bg={'rgba(182, 214, 255, 0.374)'}
      p={{ base: 4, md: 8 }}
      fontFamily="Arial, sans-serif"
    >
      <Box
        display={{ base: 'block', md: 'flex' }}
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'stretch', md: 'center' }}
        justifyContent={{ base: 'initial', md: 'center' }}
        textAlign={{ base: 'center', md: 'initial' }}
        mb={{ base: 4, md: 8 }}
      >
        <Heading
          as="h5"
          size={{ base: 'md', md: 'lg' }}
          mb={{ base: 2, md: 0 }}
          textShadow="0 0 5px pink"
        >
          🌟 Stay Updated! 🌟
        </Heading>
        <Button
          onClick={handleRefresh}
          colorScheme="red"
          size={{ base: 'sm', md: 'lg' }}
          mt={{ base: 2, md: 0 }}
          ml={{ base: 0, md: 4 }}
          leftIcon={<FaSyncAlt />}
          _hover={{ bg: '#d2122e' }}
          borderRadius="full"
          border="2px"
          color={'black'}
          fontWeight={'extrabold'}
          borderColor="#d2122e"
        >
          Refresh
        </Button>
      </Box>
      <VStack
        spacing={{ base: 8, md: 8 }}
        maxW="1200px"
        w="100%"
        m="auto"
      >
        <Box
          w="100%"
          p={6}
          textAlign="center"
          fontSize={{ base: '2rem', md: '2.5rem', lg: '3rem' }}
          fontWeight="700"
          bg="black"
          borderRadius="20px"
          color="white"
          position="relative"
          borderBottom={'10px solid gold'}
          borderLeft={'10px solid gold  '}
          borderBottomLeftRadius={'22px'}
        >
          <Text fontSize={{ base: 'md', md: 'lg', lg: 'xl' }} color="gold">
            Golden Number
          </Text>
          <HStack justify="center" mt={6} spacing={6}>
            {numbers.map((number, index) => (
              <Box
                key={index}
                w={{ base: '50px', md: '80px', lg: '80px' }}
                h={{ base: '50px', md: '80px', lg: '80px' }}
                borderRadius="50%"
                border="4px solid gold"
                mt={4}
                bg="black"
                display="flex"
                alignItems="center"
                justifyContent="center"
                _hover={{ transform: 'scale(1.1)' }}
                transition="transform 0.3s ease-in-out"
                boxShadow="0px 0px 10px rgba(0, 0, 0, 0.2)"
                borderBottom={'10px solid gold'}
                borderLeft={'10px solid gold  '}
                borderBottomLeftRadius={'22px'}
              >
                <Text fontSize="2xl" color="gold">
                  {number}
                </Text>
              </Box>
            ))}
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default LuckyNumber;
