import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import {
  Box,
  Center,
  Heading,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  color,
} from '@chakra-ui/react';
import ContentLoader from 'react-content-loader';
import ScrollArrows from '../Slidebutton';
import '../Style/RecordsTable.css';
import { useNavigate } from 'react-router-dom';

const tableStyles = {
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '20px',
};

const thStyles = {
  backgroundColor: 'teal',
  fontWeight: 'bold',
  padding: '8px',
  textAlign: 'center',
  color: 'black',
  border:"1px solid grey",
};

const tdStyles = {
  padding: '8px',
  textAlign: 'center',
  borderBottom: '1px solid #e0e0e0',
};

const buttonStyles = {
  background: 'linear-gradient(90deg, #4444bd, black)',
  color: '#fff',
  padding: '10px 20px',  // Default padding
  borderRadius: '5px',
  cursor: 'pointer',
  marginRight: '10px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  
  // Responsive Styles
  '@media (max-width: 768px)': {
    padding: '8px 16px',  // Reduced padding for tablets
  },
  '@media (max-width: 480px)': {
    padding: '6px 12px',  // Further reduced padding for mobile devices
  },
};


const containerStyles = {
  padding: '20px',
  backgroundColor: '#fff',
  borderRadius: '5px',
};

const initialLoadingState = {
  isLoading: true,
  isDataLoaded: false,
};

function RecordsTable() {
  const { id } = useParams();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(initialLoadingState);
  const [tableIndex] = useState(0);
  const [visibleRows, setVisibleRows] = useState(10); // Number of rows initially visible
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate(); // Get the navigate function from react-router-dom
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://mainproject-ul4a.onrender.com/mainchart/${id}`);
        const fetchedRecords = response.data;
  
        // Parse the startDate strings into Date objects
        const sortedRecords = fetchedRecords.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  
        // console.log('Sorted Records:', sortedRecords);
        setRecords(sortedRecords);
        setLoading({ isLoading: false, isDataLoaded: true });
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading({ isLoading: false, isDataLoaded: false });
      }
    };
  
    fetchData();
  }, [id, tableIndex]);
  

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      isFetching
    ) {
      return;
    }
    setIsFetching(true);
  };

  useEffect(() => {
    if (!isFetching) return;
    if (visibleRows >= records.length) {
      setIsFetching(false);
      return;
    }
    setVisibleRows((prevVisibleRows) => prevVisibleRows + 10); // Adjust the number of rows to load here
    setIsFetching(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  const determineTextColor = (text) => {
    const specialNumbersPattern = /(11|22|33|44|55|66|77|88|99|00|11|16|22|27|33|38|44|49|50|66|61|72|83|94|05)/;
    return specialNumbersPattern.test(text) ? 'red' : 'inherit';
  };

  const memoizedRecords = useMemo(() => records, [records]);

  const getAvailableDays = () => {
    const days = new Set();
    records.forEach((record) => {
      Object.keys(record.daysData).forEach((day) => {
        if (record.daysData[day]) {
          days.add(day);
        }
      });
    });
    return Array.from(days);
  };

  const availableDays = getAvailableDays();

  const TableRow = useCallback(
    ({ record }) => (
      <Tr>
        {/* <Td style={tdStyles}>
          <div className="date-range">
            <Text>{record.startDate}</Text>
            <Text className="date-separator">to</Text>
            <Text>{record.endDate}</Text>
          </div>
        </Td> */}
        {availableDays.map((day, index) => (
          <Td
            key={index}
            border={'1px solid grey'}
            bg={'teal.100'}
            style={{ color: determineTextColor(record.daysData[day]), fontWeight: 'bold', textAlign: 'center' }}
          >
            {record.daysData[day]}
          </Td>
        ))}
      </Tr>
    ),
    [availableDays]
  );

  return (
    <>
      <Box className="records-table-container" w="100%" maxW="1150px"  p={5} style={containerStyles}>
        <Center>
          <Heading as="h2" size={["md", "md", "xl", "2xl"]} mb={3}>
            {id}
          </Heading>
        </Center>
        <Center>
          <Text fontSize={["md", "md", "xl", "2xl"]}  color="gray.600">
            Here, you can see the {id} Jodi daily chart.
          </Text>
        </Center>
        <Center>
          <Button onClick={scrollToBottom} mt={2} ml={2} style={buttonStyles} sx={{
            '@media (max-width: 320px)': {
              fontSize: '13px',
              fontWeight: 'bold',
              w: '60%',
            },
          }}>
            Go to Bottom &nbsp; <FontAwesomeIcon bounce icon={faArrowDown} />
          </Button>
        </Center>
        <div className="scrollable-content" style={{ overflowX: 'auto' }}>
          {loading.isLoading ? (
            <ContentLoader
            speed={2}
            width="100%"
            height={200}
            viewBox="0 0 400 200"
            backgroundColor="grey"
            foregroundColor="#ecebeb"
            style={{ marginTop: '55px' }}
            >
              {Array.from({ length: 4 }).map((_, index) => (
                <rect key={index} x="0" y={index * 40} rx="4" ry="4" width="100%" height="20" />
              ))}
            </ContentLoader>
          ) : loading.isDataLoaded ? (
            <Table style={tableStyles} border={'1px solid grey'}>
              <Thead>
                <Tr>
                  {/* <Th style={thStyles}>Date Range</Th> */}
                  {availableDays.map((day, index) => (
                    <Th key={index} style={thStyles}>{day}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {memoizedRecords.map((record, index) => (
                  <TableRow key={index} record={record} />
                ))}
              </Tbody>
            </Table>
          ) : (
            <div>Data failed to load.</div>
          )}
          <Center>
          <Button
            onClick={() => navigate(-1)} // Go back to the previous path
            mt={2}
            ml={2}
            style={buttonStyles}
            sx={{
              // Styles for screens less than or equal to 636px (mobile)
              '@media (max-width: 320px)': {
                fontSize: '13px',
                fontWeight: 'bold',
                w: '60%',
              },
            }}
          >
            Back
          </Button>
            <Button onClick={scrollToTop} mt={2} ml={2} style={buttonStyles} sx={{
              '@media (max-width: 320px)': {
                fontSize: '13px',
                fontWeight: 'bold',
                w: '60%',
              },
            }}>
              Go to Top &nbsp;
              <FontAwesomeIcon bounce icon={faArrowUp} />
            </Button>
          </Center>
        </div>
      </Box>
      <ScrollArrows />
    </>
  );
}

export default RecordsTable;




